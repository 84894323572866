import React, { useState, useEffect } from "react";
import JSZip from "jszip";
import toICO from '2ico';
import { Button, Input, Select, Form, Segment } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";

export default function IconConverter() {
  const defaultPatterns = [
    { fileName: "favicon-16x16", extension: "ico", id: "ico", resolution: 16 },
    { fileName: "favicon-32x32", extension: "ico", id: "ico", resolution: 32 },
    { fileName: "favicon", extension: "ico", id: "ico-multi", resolution: 48 },
    { fileName: "apple-touch-icon", extension: "png", id: "png", resolution: 180 }
  ];

  const [file, setFile] = useState(null);
  const [patterns, setPatterns] = useState(() => {
    const savedPatterns = localStorage.getItem("patterns");
    return savedPatterns ? JSON.parse(savedPatterns) : defaultPatterns;
  });
  
  const resetPatternsToDefault = () => {
    setPatterns(defaultPatterns);
    localStorage.setItem("patterns", JSON.stringify(defaultPatterns));
  };

  useEffect(() => {
    localStorage.setItem("patterns", JSON.stringify(patterns));
  }, [patterns]);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handlePatternChange = (index, field, value) => {
    const newPatterns = [...patterns];
    newPatterns[index][field] = value;
    setPatterns(newPatterns);
  };

  const addPattern = () => {
    setPatterns([...patterns, { fileName: "ファイル名を入力", extension: "ico", id: "ico", resolution: "32" }]);
  };

  const removePattern = (index) => {
    const newPatterns = patterns.slice();
    newPatterns.splice(index, 1);
    setPatterns(newPatterns);
  };


  const convertAndDownload = async () => {
    try {
      if (!file) {
        console.error("No image file selected.");
        return;
      }

      // ZIPファイルを作成
      const zip = new JSZip();

      for (const pattern of patterns) {
        const imageResized = await resizeImage(file, pattern.resolution);
        if (pattern.id === "ico-multi") {
          // PNG画像からICOファイルに変換
          const icoData = await convertToIco(imageResized,true);

          // ICOデータをZIPに追加
          zip.file(`${pattern.fileName}.${pattern.extension}`, icoData);
        } else if (pattern.id === "ico") {
          // PNG画像からICOファイルに変換
          const icoData = await convertToIco(imageResized);

          // ICOデータをZIPに追加
          zip.file(`${pattern.fileName}.${pattern.extension}`, icoData);
        } else if (pattern.id === "png") {
          // 他の拡張子の場合、バイナリデータとして取得
          const binaryData = await toBinary(imageResized);

          // ファイルをZIPに追加
          zip.file(`${pattern.fileName}.${pattern.extension}`, binaryData);
        }
      }

      // ZIPファイルを生成してダウンロード
      zip.generateAsync({ type: "blob" }).then(function (content) {
        const url = window.URL.createObjectURL(content);
        const a = document.createElement("a");
        a.href = url;
        a.download = "icons.zip";
        a.click();
      });
    } catch (error) {
      console.error(error);
    }
  };

  const resizeImage = async (file, resolution) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function () {
        const img = new Image();
        img.src = reader.result;
        img.onload = function () {
          const canvas = document.createElement("canvas");
          canvas.width = resolution;
          canvas.height = resolution;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, resolution, resolution);
          canvas.toBlob((blob) => {
            resolve(blob);
          });
        };
      };
      reader.onerror = function (error) {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  const convertToIco = async (file, isMulti = false) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      const reader = new FileReader();
      reader.onload = function(event) {
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
  
      img.onload = function() {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
  
        if (ctx === null) {
          reject(new Error('Could not get canvas context'));
          return;
        }
  
        ctx.drawImage(img, 0, 0);
        try {
          const resolutions = isMulti ? [16, 32, 48] : [img.width];
          const icoData = toICO(canvas, resolutions);
          const base64Data = icoData.split(',')[1];
          const byteCharacters = atob(base64Data);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: 'image/x-icon' });
          resolve(blob);
        } catch (error) {
          console.error("Error in convertToIco:", error);
          reject(error);
        }
      };
    });
  };

  const toBinary = async (file) => {
    return new Promise(async (resolve, reject) => {
      try {
        const buffer = await file.arrayBuffer();
        const binaryData = new Uint8Array(buffer);

        resolve(binaryData);
      } catch (error) {
        reject(error);
      }
    });
  };

  const changeFavicon = (blobUrl) => {
    const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = blobUrl;
    document.getElementsByTagName('head')[0].appendChild(link);
  };

  const previewFavicon = async () => {
    try {
      if (!file) {
        console.error("No file selected for preview.");
        return;
      }
      
      const resizedFile = await resizeImage(file, 48);  // 16x16サイズのfaviconをプレビュー
      const blob = await convertToIco(resizedFile, false);  // 通常のICOファイルを生成
      const blobUrl = URL.createObjectURL(blob);
      changeFavicon(blobUrl);
    } catch (error) {
      console.error("Error in previewFavicon:", error);
    }
  };

  return (
    <Segment style={{ width: '960px' }}>
      <Form>
        <Form.Field>
          <Input type="file" onChange={handleFileChange} accept="image/png" placeholder="Select a PNG file" />
        </Form.Field>
        {patterns.map((pattern, index) => (
          <Form.Group widths="equal" key={index}>
            <Form.Field width={6}>
              <Input
                label="ファイル名"
                value={pattern.fileName}
                onChange={(e) => handlePatternChange(index, "fileName", e.target.value)}
              />
            </Form.Field>
            <Form.Field width={3}>
              <Select
                label="タイプ"
                options={[{ key: 'ico', value: 'ico', text: '.ico' }, { key: 'ico', value: 'ico-multi', text: '.ico (MultiIcon)' }, { key: 'png', value: 'png', text: '.png' }]}
                value={pattern.id}
                onChange={(e, { value }) => handlePatternChange(index, "id", value)}
              />
            </Form.Field>
            <Form.Field width={3}>
              <Input
                label="解像度"
                type="number"
                value={pattern.resolution}
                onChange={(e) => handlePatternChange(index, "resolution", e.target.value)}
              />
            </Form.Field>
            <Form.Field width={1}>
              <Button negative onClick={() => removePattern(index)}>削除</Button>
            </Form.Field>
          </Form.Group>
        ))}
        <Button positive onClick={addPattern}>パターンを追加</Button>
        <Button primary onClick={convertAndDownload}>変換＆ダウンロード</Button>
        <Button onClick={previewFavicon}>faviconプレビュー</Button>
        <Button negative onClick={resetPatternsToDefault}>パターンリセット</Button>
      </Form>
    </Segment>
  );
}