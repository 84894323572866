import React from 'react'
import './App.css';
import IconConverter from "./components/IconConverter"; // IconConverterコンポーネントのファイルパスを指定
import { Message } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <IconConverter /> 
        <Message style={{ fontSize: '16px',width: '960px' }}>
          <Message.Header>iconGenerator</Message.Header>
          <Message.List>
            <Message.Item>正方形のPNG画像を入力することでfaviconやリサイズ済みPNG画像を生成、zipでまとめてダウンロードできます。</Message.Item>
            <Message.Item>.ico (MultiIcon)を選択する場合は必ず解像度を48に設定してください。48/32/16のサイズでのマルチアイコン形式で生成されます。（後ほど機能拡張予定）</Message.Item>
            <Message.Item>変換、保存処理はフロントエンドのみで行われているため、画像ファイルがサーバーに送信されることはありません。</Message.Item>
            <Message.Item>「faviconプレビュー」で、現在開いているタブのfaviconが切り替わるので見栄えを確認できます。（Windows11 Chromeでのみ動作確認済み）</Message.Item>
          </Message.List>
        </Message>
        <p style={{ fontSize: '18px' }}>
          by bayashiiiiik
        </p>
      </header>
    </div>
  );
}

export default App;
